import "../css/FlodeskForm.css";
import { useEffect } from "react";

function FlodeskForm() {
    useEffect(() => {
        (function (w, d, t, h, s, n) {
            w.FlodeskObject = n;
            var fn = function () {
                (w[n].q = w[n].q || []).push(arguments);
            };
            w[n] = w[n] || fn;
            var f = d.getElementsByTagName(t)[0];
            var v =
                "?v=" + Math.floor(new Date().getTime() / (120 * 1000)) * 60;
            var sm = d.createElement(t);
            sm.async = true;
            sm.type = "module";
            sm.src = h + s + ".mjs" + v;
            f.parentNode.insertBefore(sm, f);
            var sn = d.createElement(t);
            sn.async = true;
            sn.noModule = true;
            sn.src = h + s + ".js" + v;
            f.parentNode.insertBefore(sn, f);
        })(
            window,
            document,
            "script",
            "https://assets.flodesk.com",
            "/universal",
            "fd"
        );
        window.fd("form:handle", {
            formId: "637662ccd6bfc824ac69bc5f",
            rootEl: ".ff-637662ccd6bfc824ac69bc5f",
        });
    });

    return (
        <>
            <div
                className="ff-637662ccd6bfc824ac69bc5f fd-form"
                data-ff-el="root"
                data-ff-version="3"
                data-ff-type="inline"
                data-ff-name="ribbonBanner"
                data-ff-stage="default"
            >
                {/* <!--tpl {% block config %} tpl--> */}
                <div
                    data-ff-el="config"
                    data-ff-config="eyJ0cmlnZ2VyIjp7Im1vZGUiOiJpbW1lZGlhdGVseSIsInZhbHVlIjowfSwib25TdWNjZXNzIjp7Im1vZGUiOiJtZXNzYWdlIiwibWVzc2FnZSI6IiIsInJlZGlyZWN0VXJsIjoiIn0sImNvaSI6ZmFsc2UsInNob3dGb3JSZXR1cm5WaXNpdG9ycyI6dHJ1ZSwibm90aWZpY2F0aW9uIjpmYWxzZX0="
                    style={{ display: "none" }}
                ></div>
                {/* <!--tpl {% endblock %} tpl--> */}
                <div className="ff-637662ccd6bfc824ac69bc5f__container">
                    <form
                        className="ff-637662ccd6bfc824ac69bc5f__form"
                        action="https://form.flodesk.com/forms/637662ccd6bfc824ac69bc5f/submit"
                        method="post"
                        data-ff-el="form"
                    >
                        <div className="ff-637662ccd6bfc824ac69bc5f__title">
                            <div style={{ wordBreak: "break-word" }}>
                                <div
                                    style={{
                                        color: "var(--dark-blue)",
                                        fontWeight: 700,
                                    }}
                                    role="heading"
                                    aria-level={2}
                                >
                                    Not ready to sign up?
                                </div>
                            </div>
                        </div>
                        <div className="ff-637662ccd6bfc824ac69bc5f__subtitle">
                            <div style={{ wordBreak: "break-word" }}>
                                <div data-paragraph="true">&nbsp;</div>
                                <div data-paragraph="true">
                                    <span style={{ color: "#0A1223" }}>
                                        Sign up to our newsletter and get our{" "}
                                        <strong>FREE self-care guide</strong>{" "}
                                        filled with clinically-proven mental
                                        health strategies you can implement
                                        today. We will be popping into your
                                        inbox bi-weekly with more freebies and
                                        self-care tips. Don't worry you can
                                        unsubscribe at any time.&nbsp;
                                    </span>
                                    <br />
                                    <span style={{ color: "#0A1223" }}></span>
                                </div>
                                <div
                                    data-paragraph="true"
                                    style={{ marginTop: 20 }}
                                >
                                    <span style={{ color: "#0A1223" }}>
                                        <em>
                                            Your mental health matters. We're
                                            here for you.
                                        </em>
                                    </span>
                                </div>
                                <div data-paragraph="true">&nbsp;</div>
                            </div>
                        </div>
                        <div
                            className="ff-637662ccd6bfc824ac69bc5f__content fd-form-content"
                            data-ff-el="content"
                        >
                            <div
                                className="ff-637662ccd6bfc824ac69bc5f__fields"
                                data-ff-el="fields"
                            >
                                {/* <!--tpl {% block fields %} tpl--> */}

                                <div className="ff-637662ccd6bfc824ac69bc5f__field fd-form-group">
                                    <label className="fd-form-label">
                                        <input
                                            id="ff-637662ccd6bfc824ac69bc5f-firstName"
                                            className="ff-637662ccd6bfc824ac69bc5f__control fd-form-control"
                                            type="text"
                                            maxLength="255"
                                            name="firstName"
                                            placeholder="Enter first name..."
                                            data-ff-tab="firstName::lastName"
                                            required
                                        />
                                        <div className="label-text">
                                            <div>First name</div>
                                        </div>
                                    </label>
                                </div>

                                <div className="ff-637662ccd6bfc824ac69bc5f__field fd-form-group">
                                    <label className="fd-form-label">
                                        <input
                                            id="ff-637662ccd6bfc824ac69bc5f-lastName"
                                            className="ff-637662ccd6bfc824ac69bc5f__control fd-form-control"
                                            type="text"
                                            maxLength="255"
                                            name="lastName"
                                            placeholder="Enter last name..."
                                            data-ff-tab="lastName:firstName:email"
                                            required
                                        />
                                        <div className="label-text">
                                            <div>Last name</div>
                                        </div>
                                    </label>
                                </div>

                                <div className="ff-637662ccd6bfc824ac69bc5f__field fd-form-group">
                                    <label className="fd-form-label">
                                        <input
                                            id="ff-637662ccd6bfc824ac69bc5f-email"
                                            className="ff-637662ccd6bfc824ac69bc5f__control fd-form-control"
                                            type="text"
                                            maxLength="255"
                                            name="email"
                                            placeholder="Enter email address..."
                                            data-ff-tab="email:lastName:submit"
                                            required
                                        />
                                        <div className="label-text">
                                            <div>Email address</div>
                                        </div>
                                    </label>
                                </div>

                                <input
                                    type="text"
                                    maxLength="255"
                                    name="confirm_email_address"
                                    style={{ display: "none" }}
                                />
                                {/* <!--tpl {% endblock %} tpl--> */}
                            </div>

                            <div
                                className="ff-637662ccd6bfc824ac69bc5f__footer"
                                data-ff-el="footer"
                            >
                                <button
                                    type="submit"
                                    className="ff-637662ccd6bfc824ac69bc5f__button fd-btn"
                                    data-ff-el="submit"
                                    data-ff-tab="submit"
                                >
                                    <span>Sign me up!</span>
                                </button>
                            </div>
                        </div>
                        <div
                            className="ff-637662ccd6bfc824ac69bc5f__success fd-form-success"
                            data-ff-el="success"
                        >
                            <div className="ff-637662ccd6bfc824ac69bc5f__success-message">
                                <div>
                                    <div>
                                        <div data-paragraph="true">
                                            Thank you for subscribing!
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="ff-637662ccd6bfc824ac69bc5f__error fd-form-error"
                            data-ff-el="error"
                        ></div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default FlodeskForm;
